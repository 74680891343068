<template>
  <section v-if="(amazingSuggest && amazingSuggest.length )"  id="amazingSuggest" class="py-4 pl-3 pr-3 pr-md-0 mt-5">
     <div id="amazingSuggestBanner" class="d-none d-md-flex flex-column align-items-center justify-content-center px-3">
      <img width="92" height="77" src="@/assets/images/amazingSuggest.svg" alt="vip logo" />
      <div style="width: 140px;height: 115px;">
        <img width="140" height="115" src="@/assets/images/amazingBox.png" alt="vip title" />
      </div>
      <router-link class="text-light fontsize16 font-bold mt-1" to="/products">مشاهده همه</router-link>
    </div>
    <div id="amazingSuggestproduct" class="swiper-container">
      <div class="swiper-wrapper ">
        <div id="amazingSuggestBanner" class="d-flex d-md-none flex-column align-items-center justify-content-center px-3 swiper-slide">
      <img width="92" height="77" src="@/assets/images/amazingSuggest.svg" alt="vip logo" />
      <div style="width: 140px;height: 115px;">
        <img width="140" height="115" src="@/assets/images/amazingBox.png" alt="vip title" />
      </div>
      <router-link class="text-light fontsize16 font-bold mt-1" to="/products">مشاهده همه</router-link>
    </div>

        <div
          class="swiper-slide"
          v-for="(product, index) in amazingSuggest"
          :key="index"
        >
          <Product class="vipProduct" :product="product"></Product>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Product from "@/parts/Front/components/product/Product";
import Swiper from "swiper/swiper-bundle.min";

export default {
  components: {
    Product,
  },
  mounted() {
    this.initRelatedProducts();
  },
  computed: {
    amazingSuggest() {
      if (this.$store.getters["front/getHomeData"] == null) {
        return [];
      } else {
        return this.$store.getters["front/getHomeData"]
          .suggestions;
      }
    },
  },
  watch: {
    amazingSuggest(newValue) {
      if (newValue) {
        this.initRelatedProducts(true);
      }
    },
  },
  methods: {
    initRelatedProducts(kill = false) {
      this.initSwiper("myamazingSuggest", kill, () => {
        new Swiper("#amazingSuggestproduct.swiper-container", {
          spaceBetween: 50,
          // centeredSlides: true,
          loop: false,
          speed: 500,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".similar-products .swiper-pagination",
            clickable: true,
          },
          breakpoints: {
            250: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            500: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 8,
            },
            992: {
              slidesPerView: 4.5,
              spaceBetween: 8,
            },
          },
        });
      });
    },
  },
};
</script>
<style>
section#amazingSuggest {
  display: flex;
  justify-content: space-between;
  background-color: var(--color-theme);
  border-radius: 10px;
}

div#amazingSuggestproduct {
  width: 100%;
} 
div#amazingSuggestproduct .swiper-slide {
  /* background: white;*/
  /* display: flex;
  align-items: stretch;  */
}
#amazingSuggestBanner {
  /* background: transparent !important; */
}


@media (max-width:768px){
  section#amazingSuggest {
    border-radius: 2px;
  }
}
</style>
