<template>
  <section id="brands">
    <div id="title">
      <h2>{{ brand_title }}</h2>
    </div>
    <div id="brandsSwiper" class="swiper-container">
    <div id="content" class="swiper-wrapper ">
      <router-link
        v-for="(item, index) in brands"
        :to="`/products/brand/${item.id}/${getSlug(item)}`"
        :key="index"
        class="brandItem swiper-slide"
      >
        <img class="w-100" :src="item.img" :alt="item.name" />
        <h3 class="text-dark fontsize17 fontsize-md-22">{{ item.name }}</h3>
        <!-- <strong>{{ item.name }}</strong> -->
      </router-link>
    </div>
  </div>
  </section>
</template>
<script>
import Swiper from "swiper/swiper-bundle.min";
export default {
  props: {
    brands: {
      type: Object,
      default: "",
    },
    brand_title :{
      type:String,
      default:''
    }
  },
  methods: {
    initBrands(kill = false) {
      this.initSwiper("mybrands", kill, () => {
        new Swiper("#brandsSwiper.swiper-container", {
          spaceBetween: 50,
          // centeredSlides: true,
          loop: false,
          speed: 500,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".similar-products .swiper-pagination",
            clickable: true,
          },
          breakpoints: {
            250: {
              slidesPerView: 1.5,
              spaceBetween: 5,
            },
            500: {
              slidesPerView: 3.5,
              spaceBetween: 5,
            },
            768: {
              slidesPerView: 3.5,
              spaceBetween: 8,
            },
            992: {
              slidesPerView: 4.5,
              spaceBetween: 8,
            },
          },
        });
      });
    },
    getSlug(category) {
      let slug = category.name;
      if (slug == "" || slug == null) {
        slug = "brand";
      } else {
        slug = category.name.replace(/ /g, "-");
      }
      return slug;
    },
  },
  mounted() {
    this.initBrands();
  },
};
</script>
<style scoped>
section#brands #title h2 {
  position: absolute;
  font-size: 36px;
  color: #0b0b61;
  top: 100%;
  font-family: "yekan-bakh";
  transform: translateY(-100%);
}
section#brands #title img {
  width: 25%;
}
section#brands #title {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}
section#brands {
  width: 100%;
  padding-top: 30px;
  margin-top: 50px;
}
section#brands #content .brandItem strong {
  font-size: 20px;
  font-family: "Font Awesome 5 Free";
  color: black;
}

section#brands #content .brandItem {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  /* transition: all 1s; */
}
section#brands #content {
  width: 100%;
  margin: 15px 0;
  /* display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px; */
}
/* .brandItem:hover{
  transform: scale(1.33);
} */
@media (max-width: 1200px) {
  section#brands #content {
    /* grid-template-columns: repeat(4, 1fr); */
  }
}
@media (max-width:992px) {
  section#brands #content {
    /* grid-template-columns: repeat(3, 1fr); */
  }
}
@media (max-width: 768px) {
  section#brands #content {
    /* display: flex;
    overflow-x: scroll; */
  }
  section#brands #title h2 {
    /* display: none; */
  }
}
section#brands #content::-webkit-scrollbar {
  /* width: 2px;
  height: 5px; */
  display: none;
}

/* Track */
/* section#brands #content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
} */

/* Handle */
/* section#brands #content::-webkit-scrollbar-thumb {
  background: #3c1a5b;
  border-radius: 10px;
} */
@media (max-width: 480px) {
  section#brands #content {
    /* grid-template-columns: repeat(2, 1fr); */
  }
  section#brands #content .brandItem {
    padding: 5px;
  }
}

</style>
