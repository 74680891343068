<template>
    <div id="specifications" class="tab-pane fade pb-3" :class="activeTab === 'specifications' ? 'in active show' :''">
        <div class="">
          <table class="table table-bordered fontsize-small">
            <tbody>
              <tr v-for="specification in product.specifications" :key="specification.id">
                <th scope="row" class="bg-color-fa text-center w-25 "> {{specification.label != null ? specification.label : specification.name }}</th>
                <td class="text-right pr-4">
                  <template v-if="specification.pivot && specification.pivot.specification_values != ''">
                    <span v-for="(values, index) in specification.pivot.specification_values" :key="index"> {{values.value }} {{index != specification.pivot.specification_values.length - 1 ? '-' : ''}} </span>
                  </template>
                  <template v-else-if="specification.pivot && specification.pivot.specification_value">
                    {{ specification.pivot.specification_value.value }}
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
</template>
<script>
export default {
  name: 'ProductSpecifications',
  props:['activeTab' , 'product'],
}
</script>
<style scoped>
@media screen and (max-width: 768px) {
  table tr th:first-child {
    min-width: 140px;
    text-align: right!important;
  }
}
</style>
