<template>
  <section v-if="vipProducts && vipProducts.length" id="vipProducts">
    <div id="vipHeader">
      <img src="@/assets/images/vip/vip.gif" alt="vip logo" />
      <img src="@/assets/images/vip/vip.png" alt="vip title" />
      <router-link to="/products?vip=1">مشاهده همه</router-link>
    </div>
    <div id="vipProductList" class="swiper-container">
      <div class="swiper-wrapper ">
        <div
          class="swiper-slide "
          v-for="(product, index) in vipProducts"
          :key="index"
        >
          <Product class="vipProduct" :product="product"></Product>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Product from "@/parts/Front/components/product/Product";
import Swiper from "swiper/swiper-bundle.min";

export default {
  components: {
    Product,
  },
  mounted() {
    this.initRelatedProducts();
  },
  computed: {
    vipProducts() {
      if (this.$store.getters["front/getHomeData"] == null) {
        return [];
      } else {
        return this.$store.getters["front/getHomeData"]
          .vip_unpublished_products;
      }
    },
  },
  watch: {
    vipProducts(newValue) {
      if (newValue) {
        this.initRelatedProducts(true);
      }
    },
  },
  methods: {
    initRelatedProducts(kill = false) {
      this.initSwiper("myVipProducts", kill, () => {
        new Swiper("#vipProductList.swiper-container", {
          spaceBetween: 50,
          // centeredSlides: true,
          loop: false,
          speed: 500,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".similar-products .swiper-pagination",
            clickable: true,
          },
          breakpoints: {
            250: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            500: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
            992: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            // 1200: {
            // slidesPerView: 3,
            // spaceBetween: 0,
            // }
          },
        });
      });
    },
  },
};
</script>
<style>
section#vipProducts {
  display: flex;
  justify-content: space-between;
  background-color: #121314;
  border-top-right-radius: 50px;
  margin-right: 40px;
  border-bottom-right-radius: 20px;
}
div#vipHeader img {
  width: 73%;
}
div#vipHeader img:nth-child(2) {
  transform: rotate(90deg);
  margin-top: 15px;
}
div#vipHeader a {
  color: #f7ba17;
  font-family: "yekan-bakh-bold";
  margin-top: 15px;
  border-bottom: 1px solid;
}
div#vipHeader a:hover {
  color: white;
}
div#vipHeader img:first-child {
  width: 49%;
  margin-bottom: 15px;
}
div#vipHeader {
  display: flex;
  flex-direction: column;
  width: 20%;
  align-items: center;
  gap: 20px;
}
div#vipProductList {
  width: 80%;
  padding: 15px;
}
div#vipProductList .vipProduct {
  background: white;
  border-radius: 10px;
}
@media (max-width: 480px) {
  div#vipHeader a {
    font-size: small;
  }
}
@media (max-width: 768px) {
  div#vipHeader img {
    width: 90%;
  }
  div#vipHeader img:first-child {
    width: 100%;
  }
  section#vipProducts {
    margin-right: 20px;
    border-top-right-radius: 30px;
  }
}
div#vipProductList .vipProduct img {
  border-radius: 10px;
}
</style>
