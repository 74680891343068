<template>
  <section v-if="mostSales.length||new_products.length" class="recommended-products pt-3 mt-5  mb-5">
    <div class="d-flex flex-wrap justify-content-between align-items-baseline">
      <h6 class="font-weight-bold text-color-444 ml-3 fontsize-large">
        پیشنهادی مهردخت
      </h6>
      <!-- <div class="line w-50"></div> -->
      <div class="recommended-products-category d-flex">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li v-if="new_products.length" class="nav-item ml-1 " role="presentation">
            <a
              class="nav-link py-1 px-md-3 px-2 fontsize-medium bg-none text-color-444 active"
              id="home-tab"
              data-toggle="tab"
              href="#home"
              role="tab"
              aria-controls="home"
              aria-selected="true"
              >جدیدترین</a
            >
          </li>
          <!-- <li v-if="discounts.length" class="nav-item ml-1 " role="presentation">
            <a
                class="nav-link py-1 px-md-3 px-2 fontsize-medium bg-none text-color-444"
                id="discount-tab"
                data-toggle="tab"
                role="tab"
                href="#discount"
                aria-controls="home"
                aria-selected="false"
            >تخفیفدار</a
            >
          </li> -->
          <li v-if="suggestions.length" class="nav-item ml-1" role="presentation">
            <a
              class="nav-link py-1 px-md-3 px-2 fontsize-medium bg-none text-color-444"
              id="profile-tab"
              data-toggle="tab"
              role="tab"
              href="#profile"
              aria-controls="profile"
              aria-selected="false"
              >پیشنهادی</a
            >
          </li>
          <li v-if="mostSales.length" class="nav-item ml-1" role="presentation">
            <a
              class="nav-link py-1 px-md-3 px-2 fontsize-medium bg-none text-color-444"
              id="contact-tab"
              data-toggle="tab"
              href="#mostsales"
              role="tab"
              aria-controls="contact"
              aria-selected="false"
              >پر فروش ترین</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="recommended-content tab-content mt-5" id="myTabContent">
      <div
        class="recommended-content-item tab-pane fade show active"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <div class="recommended-content-grid">
          <Product
            v-for="(product, index) in new_products"
            :key="index"
            :product="product"
          ></Product>
        </div>

        <div class="text-center">
          <router-link
            to="/products?sort=newest"
            class="recommended-products-more fontsize-small text-white bg-color-theme px-3 py-2 mt-3 d-inline-block"
            >مشاهده همه محصولات</router-link
          >
        </div>
      </div>
      <div
        class=" recommended-content-item tab-pane fade"
        id="profile"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <div class="recommended-content-grid">
          <Product
            v-for="(product, index) in suggestions"
            :key="'suggestions-' + index"
            :product="product"
          ></Product>
        </div>
        <div class="text-center">
          <router-link
            to="/products?sort=most_discounts"
            class="recommended-products-more fontsize-small text-white bg-color-theme px-3 py-2 mt-3 d-inline-block"
            >مشاهده همه محصولات</router-link
          >
        </div>
      </div>
      <!-- <div
          class="recommended-content-item tab-pane fade"
          id="discount"
          role="tabpanel"
          aria-labelledby="discount-tab"
      >
        <div class="recommended-content-grid">
          <Product
              v-for="(product, index) in discounts"
              :key="'discounts-' + index"
              :product="product"
          ></Product>
        </div>
        <div class="text-center">
          <router-link
              to="/products?sort=most_discount"
              class="recommended-products-more fontsize-small text-white bg-color-theme px-3 py-2 mt-3 d-inline-block"
          >مشاهده همه محصولات</router-link
          >
        </div>
      </div> -->
      <div
        class=" recommended-content-item tab-pane fade"
        id="mostsales"
        role="tabpanel"
        aria-labelledby="contact-tab"
      >
        <div class="recommended-content-grid">
          <Product
            v-for="(product, index) in mostSales"
            :key="'mostSales' + index"
            :product="product"
          ></Product>
        </div>
        <div class="text-center">
          <router-link
            to="/products?sort=top_sales"
            class="recommended-products-more fontsize-small text-white bg-color-theme  px-3 py-2 mt-3 d-inline-block"
            >مشاهده همه محصولات</router-link
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Product from "@/parts/Front/components/product/Product";

export default {
  name: "RecommendedProduct",
  components: {
    Product,
  },
  inject: ['mediaQueries'],
  computed: {
    showCount() {
      if (this.mediaQueries.xxxLarge) {
        return 8
      }
      if (this.mediaQueries.xxLarge) {
        return 8;
      }
      if (this.mediaQueries.xLarge) {
        return 8;
      }
      if (this.mediaQueries.medium) {
        return 6
      }
      return 6
    },
    suggestions() {
      if (this.$store.getters["front/getHomeData"] == null) {
        return [];
      } else {
        return this.getEnough(this.$store.getters["front/getHomeData"].suggestions)
      }
    },
    // discounts() {
    //   if (this.$store.getters["front/getHomeData"] == null) {
    //     return [];
    //   } else {
    //     return this.getEnough(this.$store.getters["front/getHomeData"].mostDiscount)
    //   }
    // },
    mostSales() {
      if (this.$store.getters["front/getHomeData"] == null) {
        return [];
      } else {
        return this.getEnough(this.$store.getters["front/getHomeData"].mostSales);
      }
    },
    new_products() {
      if (this.$store.getters["front/getHomeData"] == null) {
        return [];
      } else {
        return this.getEnough(this.$store.getters["front/getHomeData"].new_products);
      }
    },
  },
  methods: {
    // گرفتن مقدار لازم براساس تعدادی که باید نمایش بدیم بر اساس سایز صفحه
    getEnough(array) {
      return [...array].splice(0, Math.min(array.length, this.showCount));
    }
  }
};
</script>

<style scoped>
@media screen and(max-width: 500px) {
  .recommended-products-category a.fontsize-medium {
    font-size: 13px !important;
  }
}
</style>
