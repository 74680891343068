<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-transparent">
    <!-- Container wrapper -->
    <div class="container-fluid h-100 pr-0 pl-0">
      <!-- Collapsible wrapper -->
      <div class="collapse navbar-collapse" id="navbarExampleOnHover">
        <b-row id="menuSkelton" v-if="status">
          <b-skeleton height="25px" width="65px" class="mr-3" type="input" />
          <b-skeleton height="25px" width="65px" class="mr-3" type="input" />
          <b-skeleton height="25px" width="65px" class="mr-3" type="input" />
          <b-skeleton height="25px" width="65px" class="mr-3" type="input" />
        </b-row>
        <!-- Left links -->
        <ul v-else class="navbar-nav me-auto ps-lg-0" style="gap: 45px">
          <!-- Navbar dropdown -->
          <li
            v-for="item in menu"
            :key="item.id"
            :class="[
              item.children.length != 0
                ? ['has-children', 'dropdown-hover']
                : '',
            ]"
            class="d-flex align-items-center justify-content-center nav-item dropdown bg-transparent position-static line-break"
          >
            <a
              v-if="item.new_tab || item.link"
              :target="item.new_tab ? '_blank' : '_self'"
              :href="
                item.link
                  ? item.link
                  : $router.resolve(
                      findUrl(
                        item.linkable_type,
                        item.linkable_id,
                        item.slug,
                        item.link
                      )
                    ).href
              "
              class="nav-link px-0 dropdown-toggle"
              id="navbarDropdown"
              role="button"
              data-mdb-toggle="dropdown"
              aria-expanded="false"
            >
              {{ item.title }}
              <span v-if="item.children.length != 0">
                <svg
                  width="11"
                  height="11"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 8.5L12 15.5L5 8.5"
                    stroke="#444"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </a>
            <router-link
              v-else
              class="nav-link px-0 d-inline-block weight-light fontsize-mini text-color-000"
              :to="findUrl(item.linkable_type, item.linkable_id, item.slug)"
            >
              {{ item.title }}
              <span v-if="item.children.length != 0">
                <svg
                  width="11"
                  height="11"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 8.5L12 15.5L5 8.5"
                    stroke="#444"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </router-link>
            <!-- Dropdown menu -->
            <template v-if="$route.name == 'home'">
              <div
                class="dropdown-menu w-100 mt-0"
                aria-labelledby="navbarDropdown"
                style="border-top-left-radius: 0; border-top-right-radius: 0"
              >
                <div class="inner-dropdown-menu">
                  <!-- <div class="row my-4 justify-content-between" > -->
                  <ul
                    v-if="item.children.length != 0"
                    class="d-flex list-unstyled align-items-start bg-white"
                    style="gap: 2.5rem; min-width: 45%"
                  >
                    <li
                      v-for="childMenu in item.children"
                      :class="{
                        'has-child-children': childMenu.children.length != 0,
                      }"
                      :key="childMenu.id"
                      class="nav-item"
                    >
                      <div class="d-flex">
                        <img
                          v-if="childMenu.icon && childMenu.icon.type == 'svg'"
                          :src="childMenu.icon.url"
                          :alt="childMenu.icon.name"
                        />
                        <a
                          :target="childMenu.new_tab ? '_blank' : '_self'"
                          class="nav-link fontsize18 text-dark"
                          v-if="childMenu.new_tab || childMenu.link"
                          :href="
                            childMenu.link
                              ? childMenu.link
                              : $router.resolve(
                                  findUrl(
                                    childMenu.linkable_type,
                                    childMenu.linkable_id,
                                    childMenu.slug,
                                    childMenu.link
                                  )
                                ).href
                          "
                          >{{ childMenu.title }}
                          <span v-if="childMenu.children.length != 0">
                            <svg
                              style="position: relative; top: -2px"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-chevron-left"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                              />
                            </svg>
                          </span>
                        </a>
                        <router-link
                          v-else
                          class="nav-link fontsize18 text-dark"
                          :to="
                            findUrl(
                              childMenu.linkable_type,
                              childMenu.linkable_id,
                              childMenu.slug
                            )
                          "
                          >{{ childMenu.title }}
                          <span v-if="childMenu.children.length != 0">
                            <svg
                              style="position: relative; top: -2px"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-chevron-left"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                              />
                            </svg>
                          </span>
                        </router-link>
                      </div>
                      <ul
                        v-if="childMenu.children.length != 0"
                        class="list-unstyled justify-content-center align-items-center bg-white"
                      >
                        <li
                          :class="{
                            'has-child-children':
                              childrenMenu.children.length != 0,
                          }"
                          v-for="childrenMenu in childMenu.children"
                          :key="childrenMenu.id"
                          class="nav-item"
                        >
                          <div class="d-flex">
                            <img
                              v-if="
                                childrenMenu.icon &&
                                childrenMenu.icon.type == 'svg'
                              "
                              :src="childrenMenu.icon.url"
                              :alt="childrenMenu.icon.name"
                              style="opacity: 0.5"
                            />

                            <a
                              v-if="childrenMenu.new_tab || childrenMenu.link"
                              class="nav-link childMenu"
                              :target="childMenu.new_tab ? '_blank' : '_self'"
                              :href="
                                childrenMenu.link
                                  ? childrenMenu.link
                                  : $router.resolve(
                                      findUrl(
                                        childrenMenu.linkable_type,
                                        childrenMenu.linkable_id,
                                        childrenMenu.slug,
                                        childrenMenu.link
                                      )
                                    ).href
                              "
                              >{{ childrenMenu.title }}
                              <span v-if="childrenMenu.children.length != 0">
                                <svg
                                  style="position: relative; top: -2px"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-chevron-left"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                                  />
                                </svg>
                              </span>
                            </a>
                            <router-link
                              v-else
                              class="nav-link childMenu"
                              :to="
                                findUrl(
                                  childrenMenu.linkable_type,
                                  childrenMenu.linkable_id,
                                  childrenMenu.slug
                                )
                              "
                              >{{ childrenMenu.title }}
                              <span v-if="childrenMenu.children.length != 0">
                                <svg
                                  style="position: relative; top: -2px"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-chevron-left"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                                  />
                                </svg>
                              </span>
                            </router-link>
                          </div>
                          <!-- THIRD MENU -->
                          <ul
                            v-if="childrenMenu.children.length != 0"
                            class="dropdown-child-child-menu-hover list-unstyled justify-content-center align-items-center bg-white"
                          >
                            <li
                              v-for="childrenChildMenu in childrenMenu.children"
                              :key="childrenChildMenu.id"
                              class="nav-item"
                            >
                              <a
                                v-if="childrenChildMenu.link"
                                class="nav-link childMenu"
                                :href="childrenChildMenu.link"
                                >{{ childrenChildMenu.title }}</a
                              >
                              <router-link
                                v-else
                                class="nav-link childMenu"
                                :to="
                                  findUrl(
                                    childrenChildMenu.linkable_type,
                                    childrenChildMenu.linkable_id,
                                    childrenChildMenu.slug
                                  )
                                "
                                >{{ childrenChildMenu.title }}
                              </router-link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <!-- <div class="dropdown-menu-line"></div> -->
                  <div
                    v-if="
                      item.icon &&
                      (item.icon.type == 'image' || item.icon.type == 'webp')
                    "
                    item.icon
                    class="mega-menu-img-holder mr-5"
                  >
                    <img
                      class="w-100"
                      :src="item.icon.url"
                      :alt="item.icon.name"
                    />
                  </div>
                  <!-- </div> -->
                </div>
              </div>
            </template>
          </li>
        </ul>
        <!-- Left links -->
      </div>
      <!-- Collapsible wrapper -->
    </div>
    <!-- Container wrapper -->
  </nav>
</template>

<script>
import findUrl from "@/libraries/links/LinkResolver";
import { BRow, BSkeleton } from "bootstrap-vue";
import DownChev from "./svg/DownChev.vue";
export default {
  name: "Nav",
  components: {
    BRow,
    BSkeleton,
    DownChev,
  },
  computed: {
    menu() {
      if (this.$store.getters["front/getHomeData"] != null) {
        return this.$store.getters["front/getHomeData"].menu.header;
      } else {
        return this.$store.getters["front/getHomeData"];
      }
    },
    status() {
      return this.$store.getters["front/getHomeDataStatus"];
    },
  },

  methods: {
    findUrl,
  },
};
</script>

<style scoped>
.dropdown-hover:hover > .dropdown-menu {
  display: inline-block;
  border-top: 1px solid #00000017;
}

.dropdown-hover > .dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/
  pointer-events: none;
}
.dropdown-menu {
  top: 97%;
  max-width: 100%;
  border: unset;
  z-index: 98;
  padding: 40px 0;
  box-shadow: 0 8px 8px #00000026;
  right: 0;
  left: 0;
  overflow: hidden;
}
.inner-dropdown-menu {
  display: flex;
  align-items: flex-start;
  min-width: 0;
  width: 100%;
  max-width: 100%;
  justify-content: center;
  padding: 0 16px;
}
/* .dropdown-menu-line{
  display: block;
    width: 1px;
    margin: 24px 16px;
    background-color: #e0e0e2;
    height: 100%;
    flex-shrink: 0;
} */
.navbar {
  padding: 0 !important;
  position: inherit;
}
.dropdown-hover:hover .dropdown-toggle {
  border-bottom: 2px solid #000;
}
.dropdown-toggle::after {
  display: none;
}
li .nav-item {
  text-align: start;
}
.mega-menu-img-holder {
  width: 300px;
  position: relative;
  /* height: 300px; */
}
.mega-menu-img-holder::before {
  content: "";
  position: absolute;
  display: block;
  width: 1px;
  left: 100%;
  top: 5%;
  margin-inline: 30px;
  background-color: #e0e0e2;
  height: 90%;
}
/* .line-break:nth-child(4n)::after {
  content: "";
  display: block;
  height: 18px;
  margin-right: 2rem;
  border-right: 1px solid rgba(0, 0, 0, 0.9);
} */
/* .childMenu {
    color: #666;
  }
  #menuSkelton {
    display: flex;
    justify-content: space-around;
  }
  .mega-menu{
    background: #ffffff;
    min-height: 488px;
    width: 100vw;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1000;
    border-top:1px solid #ccc;
  } */
</style>
