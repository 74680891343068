<template>
  <svg
    data-v-0a087d6e=""
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      data-v-0a087d6e=""
      cx="11.7666"
      cy="11.7666"
      r="8.98856"
      stroke="#000"
      stroke-width="1"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></circle>
    <path
      data-v-0a087d6e=""
      d="M18.0183 18.4851L21.5423 22"
      stroke="#000"
      stroke-width="1"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
