<template>
  <section class="campaigns pb-3 pt-sm-3 pt-1 mt-sm-4 mt-2">
    <div v-for="(flash, index) in flashes" :key="flash.id" class="container" :class="index != 0 ? 'py-5' : ''">

    <HeaderSection :mode="'flash'" :textBtn="'محصولات بیشتر'" :status="false" :text="flash.title" :route="'/products?flash_id=' + flash.id " />
      <div class="campaigns-content overflow-hidden">
        <div class="campaigns-banner w-100 h-100 position-relative">
          <img loading="lazy" class="w-100 h-100" :src="flash.image.url" alt="">
          <router-link :to="'/products?flash_id=' + flash.id "
            class="campaigns-content-more-btn py-2 px-3 bg-color-theme text-white fontsize-mini">مشاهده
            محصولات</router-link>
        </div>
        <div class="campaigns-slide h-100 w-100 bg-color-fa">
          <div class="swiper-container bg-color-ea h-100 my-flash-products" :id="'flash-swiper' + flash.id">
            <div class="swiper-wrapper " v-if="flash.products != '' && !disabledSliders.includes('myProducts')">
              <FlashProduct v-for="product in flash.products" :key="product.id" :product="product" />
            </div>
            <div class="swiper-button-next">
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"
                class="bi bi-chevron-compact-left" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                  d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"
                  fill="#999" />
              </svg>
            </div>
            <div class="swiper-button-prev">
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"
                class="bi bi-chevron-compact-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                  d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"
                  fill="#999" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import HeaderSection from '@/parts/Front/components/HeaderSection'

  import Swiper from 'swiper/swiper-bundle.min'
  import FlashProduct from '@/parts/Front/components/flash/FlashProduct'
  export default {
    name: "Flashes",
    props: ['flash', 'flashes'],
    components: {
      FlashProduct,HeaderSection
    },
    data() {
      return {
        disabledSliders: []
      }
    },
    watch: {
      // flashes(flashes) {
      //   console.log(flashes)
      // }
    },
    mounted() {
      // if (this.flashes) {
      //   this.initFlashProducts()
      //   setTimeout(function () {
      //     window.dispatchEvent(new Event("resize"));
      //   }, 100);
      // }
      this.initFlashProducts()
      // setTimeout(function () {
      //   window.dispatchEvent(new Event("resize"));
      // }, 200);
    },
    methods: {
      initFlashProducts(kill = false) {
        this.initSwiper('myProducts', kill, () => {
          new Swiper('.campaigns .swiper-container', {
            spaceBetween: 1,
            //   centeredSlides: true,
            loop: false,
            speed: 500,
            autoplay: {
              delay: 5000,
              disableOnInteraction: false,
            },
            navigation: {
              nextEl: '.campaigns .swiper-button-next',
              prevEl: '.campaigns .swiper-button-prev',
            },
            breakpoints: {
              300: {
                slidesPerView: 1,
                spaceBetween: 1,
              },
              500: {
                slidesPerView: 2,
                spaceBetween: 1,
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 1,
              }

              // 1200: {
              // slidesPerView: 3,
              // spaceBetween: 0,
              // }
            }
          });
        })

      },
    }
  }
</script>

<style >
  /*.campaigns-content {*/
  /*  height: 410px ;*/
  /*}*/
  /*@media screen and (min-width: 1600px){*/
  /*  .campaigns-content {*/
  /*    height: 550px ;*/
  /*  }*/
  /*}*/
  .campaigns {
    height: 100%;
  }
</style>
