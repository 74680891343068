<template>
 <div>
  <slider />


  <!-- <StoryCategories/> -->
    <main class="main container container-2 pt-1 p-sm-5">
      <!-- <Brands :brand_title="'قطعات بدنه'" :brands="brands2"/> -->
      <!-- <SearchSection /> -->
      <NewProduct/>
      <!-- <specialCategoriesSlide/> -->
      <!-- <VipSection /> -->
      <!-- <FourBanners /> -->
      <!-- <MostDiscountProduct/> -->
      <Banners :banners="banners[0]"></Banners>
      <AmazingProduct/>
      <!-- <Brands :brand_title="'برند های خودرو'" :brands="brands1"/> -->
      <!-- <RecommendedProduct></RecommendedProduct> -->
      <!-- <template v-if="$store.getters['front/getHomeData'] != null && $store.getters['front/getHomeData'].flashes != ''">
        <Flashes v-for="flash in $store.getters['front/getHomeData'].flashes" :key="flash.id" :flash="flash"></Flashes>
      </template> -->
      <Flashes v-if="$store.getters['front/getHomeData'] != null && $store.getters['front/getHomeData'].flashes != ''" :flashes="$store.getters['front/getHomeData'].flashes" />
      <MostSales/>
      <Banners :banners="banners[1]"></Banners>
      <Weblogs></Weblogs>
      <!-- <LastInstaPosts class="mt-5" v-if="showInstagram"></LastInstaPosts> -->
<!--      <ScrollToTop></ScrollToTop>-->

    </main>

 </div>

</template>

<script>
import VipSection from "@/parts/Front/components/VipSection"
  import slider from '@/parts/Front/components/shared/Header'
  import RecommendedProduct from '@/parts/Front/components/RecommendedProduct'
  import Banners from '@/parts/Front/components/Banners'
  import Flashes from "@/parts/Front/components/FlashProducts";
  import Weblogs from "@/parts/Front/components/Weblogs";
  import SearchSection from "@/parts/Front/components/SearchSection"
  // import LastInstaPosts from "@/parts/Front/components/LastInstaPosts";
  import ScrollToTop from "@/parts/Front/components/ScrollToTop";
  import debounce from 'lodash/debounce'
  import FourBanners from "@/parts/Front/components/FourBanners";
import StoryCategories from "../components/StoryCategories.vue";
import MostDiscountProduct from "../components/MostDiscountProduct.vue";
import AmazingProduct from "../components/AmazingProduct.vue";
import MostSales from "../components/HomeProducts/MostSales.vue";
import NewProduct from "../components/HomeProducts/NewProduct.vue";
import specialCategoriesSlide from "../components/HomeProducts/specialCategoriesSlide.vue";
import Brands from "../components/HomeProducts/Brands.vue";
  export default {
    name: 'Home',
    components: {
      FourBanners,VipSection,
      slider,
      RecommendedProduct,
      Banners,
      Flashes,
      Weblogs,
      SearchSection,
      // LastInstaPosts,
      ScrollToTop,
      StoryCategories,
      MostDiscountProduct,
      AmazingProduct,
      MostSales,
      NewProduct,
      specialCategoriesSlide,
      Brands
    },
    data() {
      return {
        showInstagram: true,
        reRenderInstagramFunction: debounce(this.reRenderInstagram, 250),
        brands1:[
          {
            id:1,
            name:'سمند',
            img:'https://s2.uupload.ir/files/ikc_hws5.jpg'
          },
          {
            id:2,
            name:'جیلی',
            img:'https://s2.uupload.ir/files/j_t5o6.jpg'
          },
          {
            id:3,
            name:'کیاموتور',
            img:'https://s2.uupload.ir/files/ki_dtj.jpg'
          },
          {
            id:4,
            name:'لیفان',
            img:'https://s2.uupload.ir/files/l_pdvo.jpg'
          },
          {
            id:5,
            name:'مزدا',
            img:'https://s2.uupload.ir/files/mz_ln60.jpg'
          },
          {
            id:6,
            name:'پژو',
            img:'https://s2.uupload.ir/files/pj_q28.jpg'
          },
          {
            id:7,
            name:'رنو',
            img:'https://s2.uupload.ir/files/r_ki2d.jpg'
          },
          {
            id:8,
            name:'سایپا',
            img:'https://s2.uupload.ir/files/s_sv4a.jpg'
          },
        ],
        brands2:[
          {
            id:1,
            name:'سپر',
            img:'https://s2.uupload.ir/files/b_nzhf.jpg'
          },
          {
            id:2,
            name:'درب ها',
            img:'https://s2.uupload.ir/files/d_9tl.jpg'
          },
          {
            id:3,
            name:'قطعات داخلی',
            img:'https://s2.uupload.ir/files/da_gf9.jpg'
          },
          {
            id:4,
            name:'گلگیر',
            img:'https://s2.uupload.ir/files/f_jibz.jpg'
          },
          {
            id:5,
            name:'شیشه',
            img:'https://s2.uupload.ir/files/g_sf03.jpg'
          },
          {
            id:6,
            name:'کاپوت',
            img:'https://s2.uupload.ir/files/h_pxh5.jpg'
          },
          {
            id:7,
            name:'جلوپنجره',
            img:'https://s2.uupload.ir/files/j_bjpo.jpg'
          },
          {
            id:8,
            name:'چراغ جلو',
            img:'https://s2.uupload.ir/files/l_gavm.jpg'
          },
           {
            id:9,
            name:'چراغ عقب',
            img:'https://s2.uupload.ir/files/l2_3gcd.jpg'
          },
           {
            id:10,
            name:'آینه',
            img:'https://s2.uupload.ir/files/m_9blu.jpg'
          },
           {
            id:11,
            name:'سقف',
            img:'https://s2.uupload.ir/files/r_ejbn.jpg'
          },
        ],
      }
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.reRenderInstagramFunction)
    },
    mounted() {
      window.removeEventListener('resize', this.reRenderInstagramFunction)
      window.addEventListener('resize', this.reRenderInstagramFunction)
    },
    methods: {
      reRenderInstagram() {
        this.showInstagram = false;
        this.$nextTick(() => {
          this.showInstagram = true
        })
      }
    },
    computed:{
      banners(){
        if(this.$store.getters['front/getHomeData'] != null) {
          let advertise = this.$store.getters['front/getHomeData'].advertise
          return [
            [advertise.banner_1 , advertise.banner_2],
            [advertise.banner_3 , advertise.banner_4]
          ]
        } else {
          return [
            [null, null],
            [null, null],
          ]
        }
      }
    }
  }
</script>
