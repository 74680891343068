<template>
  <section v-if="mostSales && mostSales.length" class="mt-5">
    <div>
      <HeaderSection
        :textBtn="'مشاهده بیشتر'"
        :text="'پرفروش ها'"
        :route="'/products?sort=top_sales'"
      />
      <div id="mostSalesSwiper" class="swiper-container">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(product, index) in mostSales"
            :key="index"
          >
            <Product :product="product"></Product>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Product from "@/parts/Front/components/product/Product";
import Swiper from "swiper/swiper-bundle.min";
import HeaderSection from "@/parts/Front/components/HeaderSection";
export default {
  components: {
    Product,
    HeaderSection,
  },
  computed: {
    mostSales() {
      if (this.$store.getters["front/getHomeData"] == null) {
        return [];
      } else {
        return this.$store.getters["front/getHomeData"].mostSales;
      }
    },
  },
  methods: {
    initMostSales(kill = false) {
      this.initSwiper("myMostSales", kill, () => {
        new Swiper("#mostSalesSwiper.swiper-container", {
          spaceBetween: 50,
          loop: false,
          speed: 500,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".similar-products .swiper-pagination",
            clickable: true,
          },
          breakpoints: {
            250: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            500: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
            992: {
              slidesPerView: 5,
              spaceBetween: 12,
            },
            // 1200: {
            // slidesPerView: 3,
            // spaceBetween: 0,
            // }
          },
        });
      });
    },
  },
  watch: {
    mostSales(newValue) {
      if (newValue) {
        this.initMostSales(true);
      }
    },
  },
  mounted() {
    this.initMostSales();
  },
};
</script>

<style></style>
