<template>
  <div v-if="!status" :class="{ 'main-header': this.$route.name == 'home' }">
    <header class="container pt-4 pb-lg-0 pb-4">
      <section
        class="d-lg-flex d-none align-items-center justify-content-between"
      >
        <div
          class="d-flex align-items-center header-icons header-flex-col"
          style="margin-bottom: 70px"
        >
          <button
            class="bg-transparent border-bottom border-dark"
            type="button"
            @click="showCloseSearchBox()"
          >
            <searchSvg />
            <input
              type="text"
              readonly
              class="bg-transparent pr-2 font-weight-lighter text-color-000 fontsize-mini"
              value="جستجوی محصولات"
            />
          </button>
        </div>
        <div
          class="d-lg-flex flex-column d-none align-items-center justify-content-center flex-grow-1 px-3"
        >
          <Logo />
          <!-- <div class="header-line"></div> -->
          <MegaMeun class="mt-3" />
        </div>
        <div
          class="d-flex align-items-center header-icons header-flex-col"
          style="margin-bottom: 70px"
        >
          <button
            class="bg-transparent ml-3"
            type="button"
            @click="cartListsOpen()"
          >
            <CartButton />
          </button>
          <LoginUser :atlasTheme="true" />
        </div>
      </section>
      <section
        class="d-flex d-lg-none align-items-center justify-content-between"
      >
        <div>
          <button
            type="button"
            id="all-categories"
            class="all-categories bg-transparent resp-menu-btn"
            @click="$emit('openRespMenu')"
          >
            <MenuBar />
          </button>
        </div>
        <Logo />

        <div class="d-flex align-items-center" style="gap: 10px">
          <button
            class="bg-transparent"
            type="button"
            @click="showCloseSearchBox()"
          >
            <searchSvg />
          </button>
          <button
            class="bg-transparent ml-3"
            type="button"
            @click="cartListsOpen()"
          >
            <CartButton />
          </button>
        </div>
      </section>
    </header>
  </div>
</template>

<script>
import searchSvg from "./svg/Search.vue";
import CartButton from "./cart/CartButton.vue";
import { cartListsOpen, openSearch } from "@/libraries/functions";
import Logo from "@/parts/Front/components/AtlasLogo";
import MegaMeun from "../components/MegaMenu.vue";
import LoginUser from "@/parts/Front/components/LoginUser";
import UserSvg from "./svg/User.vue";
import MenuBar from "./svg/MenuBar.vue";
export default {
  components: {
    searchSvg,
    Logo,
    MegaMeun,
    CartButton,
    LoginUser,
    UserSvg,
    MenuBar,
  },
  computed: {
    isLogin() {
      return this.$store.getters["front/getLoginStatus"];
    },
    status() {
      return this.$store.getters["front/getHomeDataStatus"];
    },
  },
  methods: {
    cartListsOpen() {
      cartListsOpen();
      this.$router.push(this.$route.fullPath + "#cart-modal");
    },
    showCloseSearchBox() {
      openSearch();
    },
  },
};
</script>

<style scoped>
/* .header-icons button {
  padding: 0 12px 0 0;
} */
.products-list-logo {
  position: absolute;
  /* top: 10px; */
  /* background-color: #ffffff; */
  /* border-radius: 50%; */
  /* padding: 6px; */
  right: 50%;
  transform: translateX(50%);
  z-index: 99;
}
.header-flex-col {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 100%;
  -ms-flex-preferred-size: 300px;
  flex-basis: 230px;
}
.header-line {
  height: 1px;
  width: 500px;
  max-width: 100%;
  background-color: #000;
  margin-top: 25px;
}
</style>
